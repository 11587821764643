const investmentDataMapper = (data) => {
	return data.map((row) => ({
		amount: row.amount,
		benefit: row.benefit,
		dateLiquidation: row.dateLiquidation || 'N/A',
		email: row.email || 'N/A',
		incomeRecivedDate: row.incomeRecivedDate || 'N/A',
		investorProfile: row.investorProfile || 'N/A',
		investorTIR: row.investorTIR || 'N/A',
		liquidation: row.liquidation || 'N/A',
		projectAddress: row.projectAddress || 'N/A',
		retention: row.retention || '0%',
		retentionAmount: row.retentionAmount,
		startDateUpdated: row.startDateUpdated || 'N/A',
		toPay: row.toPay || 0,
		feeRate: row.feeRate || 0,
		fee: row.fee || 0,
		withHoldingAmount: row.withHoldingAmount || '0€',
		businessName: row.businessName || 'Unknown Business',
		dni: row.dni || 'N/A',
		country: row.country || 'España',
		postalCode: row.postalCode || '28001',
		bankAccountNumber: row.bankAccountNumber || 'N/A',
		address: row.address || 'N/A',
	}))
}

export default investmentDataMapper
